import Layout from ".././components/Layout";
import Card from "../components/Card";
import SearchModal from "../components/SearchModal";
import TableItems from "../components/TableItems";

import "./standard.scss";
import { useEffect, useState } from "react";
import ModalOverlay from "../components/ModalOverlay";

function StandardPage(props) {
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);

  const onCloseSearchModal = () => {
    setIsSearchModalVisible(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 75 && e.metaKey) {
      setIsSearchModalVisible((isVisible) => !isVisible);
    }
    if (e.keyCode === 27) {
      setIsSearchModalVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {}, []);

  return (
    <>
      <Layout key={props.navButtons} navButtons={props.navButtons}>
        <main className="content">{props.children}</main>
      </Layout>
      {isSearchModalVisible && <SearchModal onClose={onCloseSearchModal} />}
      {isSearchModalVisible && <ModalOverlay />}
    </>
  );
}

export default StandardPage;
