import {
  AnalyticsData,
  Attention,
  CloudflareAccess,
  CloudflareGateway,
  CloudflarePages,
  CloudflareRegistrar,
  CloudflareStreamDelivery,
  CloudflareWorkers,
  CloudflareSpectrum,
  CloudInternet,
  Connect3,
  DDoSAttack,
  DocumentationList,
  Image,
  InnovationThinking,
  Home,
  NetworkScale,
  Overview,
  PaymentsCreditCard,
  PerformanceAccelerationBolt,
  PerformanceRouting,
  PerformanceWrench,
  PlatformApps,
  ReliabilityDNS,
  ReliabilityLoadBalancer,
  Search,
  SecurityBots,
  SecurityLock,
  SecurityScraping,
  SecurityShield,
  SecurityWAF,
  Server2,
  User,
  UserAddMember,
  UserMulti,
} from "./icons";

export const initialNavButtons = [
  {
    type: "search",
    label: "Search",
    icon: Search,
  },
  {
    type: "button-icon-svg",
    label: "Home",
    path: "/",
    icon: Home,
  },
  {
    type: "title",
    label: "Analytics",
  },
  {
    type: "button-icon-svg",
    label: "Performance Analytics",
    path: "/account-analytics",
    icon: AnalyticsData,
  },
  {
    type: "button-icon-svg",
    label: "Security Analytics",
    path: "/account-analytics",
    icon: AnalyticsData,
  },
  {
    type: "title",
    label: "Services",
  },
  {
    type: "button-icon-svg",
    label: "Application Services",
    path: "/application-services",
    icon: CloudInternet,
  },
  {
    type: "button-icon-svg",
    label: "Network Services",
    path: "/data-centre-services",
    icon: NetworkScale,
  },
  {
    type: "button-icon-svg",
    label: "Cloudflare for Teams",
    path: "/teams",
    icon: UserMulti,
  },
  {
    type: "button-icon-svg",
    label: "Cloudflare for Creators",
    path: "/creators",
    icon: InnovationThinking,
  },
  {
    type: "title",
    label: "Applications",
  },
  {
    type: "button-icon-svg",
    label: "Firewall",
    path: "/firewall",
    icon: SecurityWAF,
  },
  {
    type: "button-icon-svg",
    label: "Pages",
    path: "/pages",
    icon: CloudflarePages,
  },
  {
    type: "button-icon-svg",
    label: "Registrar",
    path: "/registrar",
    icon: CloudflareRegistrar,
  },
  {
    type: "button-icon-svg",
    label: "Workers",
    path: "/workers",
    icon: CloudflareWorkers,
  },
  {
    type: "title",
    label: "Account Management",
  },
  {
    type: "button-icon-svg",
    label: "Billing",
    path: "/account-management/billing",
    icon: PaymentsCreditCard,
  },
  {
    type: "button-icon-svg",
    label: "Members",
    path: "/account-management/members",
    icon: UserAddMember,
  },
  {
    type: "button-icon-svg",
    label: "Configurations",
    path: "/account-management/configurations",
    icon: PerformanceWrench,
  },
  {
    type: "button-icon-svg",
    label: "Notifications",
    path: "/account-management/notifications",
    icon: PaymentsCreditCard,
  },
];

export const applicationServicesNavButtons = [
  {
    type: "search",
    label: "Search",
    icon: Search,
  },
  {
    type: "button-icon-svg",
    label: "Home",
    path: "/",
    icon: Home,
  },
  {
    type: "button-icon-svg",
    label: "Application Overview",
    path: "/application-services",
    icon: Overview,
  },
  {
    type: "title",
    label: "Analytics & Monitoring",
  },
  {
    type: "button-icon-svg",
    label: "Analytics",
    path: "/routing/dns",
    icon: AnalyticsData,
  },
  {
    type: "button-icon-svg",
    label: "Logs",
    path: "/routing/dns",
    icon: DocumentationList,
  },
  {
    type: "title",
    label: "Routing",
  },
  {
    type: "button-icon-svg",
    label: "DNS",
    path: "/routing/dns",
    icon: ReliabilityDNS,
  },
  {
    type: "button-icon-svg",
    label: "Spectrum",
    path: "/routing/spectrum",
    icon: CloudflareSpectrum,
  },
  {
    type: "button-icon-svg",
    label: "Workers Routes",
    path: "/routing/workers-routes",
    icon: CloudflareWorkers,
  },
  {
    type: "button-icon-svg",
    label: "Argo Tunnel Routes",
    path: "/routing/argo-tunnel-routes",
    icon: Connect3,
  },
  {
    type: "title",
    label: "Security",
  },
  {
    type: "button-icon-svg",
    label: "SSL/TLS",
    path: "/security/ssl-tls",
    icon: SecurityLock,
  },
  {
    type: "button-icon-svg",
    label: "Bot Mitigation",
    path: "/security/waf",
    icon: SecurityBots,
  },
  {
    type: "button-icon-svg",
    label: "DDoS Mitigation",
    path: "/security/waf",
    icon: DDoSAttack,
  },
  {
    type: "button-icon-svg",
    label: "Web Application Firewall",
    path: "/security/waf",
    icon: SecurityWAF,
  },
  // {
  //   type: "button-sans-icon",
  //   label: "Analytics",
  //   path: "/security/waf/analytics",
  // },
  // {
  //   type: "button-sans-icon",
  //   label: "Managed Firewall Rules",
  //   path: "/security/waf/managed-firewall-rules",
  // },
  // {
  //   type: "button-sans-icon",
  //   label: "Custom Firewall Rules",
  //   path: "/security/waf/custom-firewall-rules",
  // },
  {
    type: "title",
    label: "Performance",
  },
  {
    type: "button-icon-svg",
    label: "Caching",
    path: "/performance/caching",
    icon: Server2,
  },
  {
    type: "button-icon-svg",
    label: "Load Balancer",
    path: "/performance/caching",
    icon: ReliabilityLoadBalancer,
  },
  {
    type: "button-icon-svg",
    label: "Page Speed",
    path: "/performance/page-speed",
    icon: PerformanceAccelerationBolt,
  },
  {
    type: "button-icon-svg",
    label: "Image Resizing",
    path: "/performance/image-resizing",
    icon: Image,
  },
  {
    type: "button-icon-svg",
    label: "Argo Smart Routing",
    path: "/performance/argo-smart-routing",
    icon: PerformanceRouting,
  },
  {
    type: "title",
    label: "Content Moderation",
  },
  {
    type: "button-icon-svg",
    label: "Scrape Shield",
    path: "/content-moderation/scrape-shield",
    icon: SecurityScraping,
  },
  {
    type: "button-icon-svg",
    label: "CSAM",
    path: "/content-moderation/csam",
    icon: Image,
  },
  {
    type: "button-icon-svg",
    label: "Custom Error Pages",
    path: "/content-moderation/custom-error-pages",
    icon: Attention,
  },
  {
    type: "title",
    label: "Expansion",
  },
  {
    type: "button-icon-svg",
    label: "Apps",
    path: "/apps/",
    icon: PlatformApps,
  },
];

export const dataCenterServicesNavButtons = [
  {
    type: "search",
    label: "Search",
    icon: Search,
  },
  {
    type: "button-icon-svg",
    label: "Home",
    path: "/",
    icon: Home,
  },
  {
    type: "button-icon-svg",
    label: "Data Center Overview",
    path: "/data-centre-services",
    icon: Overview,
  },
  {
    type: "title",
    label: "Analytics & Monitoring",
  },
  {
    type: "button-icon-svg",
    label: "Analytics",
    path: "/routing/dns",
    icon: AnalyticsData,
  },
  {
    type: "button-icon-svg",
    label: "Logs",
    path: "/routing/dns",
    icon: DocumentationList,
  },
  {
    type: "title",
    label: "Security",
  },

  {
    type: "button-icon-svg",
    label: "Magic Firewall",
    path: "/security/waf",
    icon: SecurityWAF,
  },
];

export const teamsNavButtons = [
  {
    type: "search",
    label: "Search",
    icon: Search,
  },
  {
    type: "button-icon-svg",
    label: "Home",
    path: "/",
    icon: Home,
  },
  {
    type: "button-icon-svg",
    label: "Teams Overview",
    path: "/teams",
    icon: Overview,
  },
  {
    type: "title",
    label: "Analytics & Monitoring",
  },
  {
    type: "button-icon-svg",
    label: "Analytics",
    path: "/security/waf",
    icon: AnalyticsData,
  },
  {
    type: "button-icon-svg",
    label: "Logs",
    path: "/security/waf",
    icon: DocumentationList,
  },
  {
    type: "title",
    label: "Applications",
  },
  {
    type: "button-icon-svg",
    label: "Gateway",
    path: "/routing/dns",
    icon: CloudflareGateway,
  },
  {
    type: "button-icon-svg",
    label: "Access",
    path: "/security/waf",
    icon: CloudflareAccess,
  },
  {
    type: "button-icon-svg",
    label: "Configuration",
    path: "/security/waf",
    icon: PerformanceWrench,
  },
  {
    type: "title",
    label: "Management",
  },
  {
    type: "button-icon-svg",
    label: "My Team",
    path: "/security/waf",
    icon: UserMulti,
  },
  {
    type: "button-icon-svg",
    label: "Account",
    path: "/security/waf",
    icon: User,
  },
];

export const creatorsNavButtons = [
  {
    type: "search",
    label: "Search",
    icon: Search,
  },
  {
    type: "button-icon-svg",
    label: "Home",
    path: "/",
    icon: Home,
  },
  {
    type: "button-icon-svg",
    label: "Creators Overview",
    path: "/creators",
    icon: Overview,
  },
  {
    type: "title",
    label: "Analytics & Monitoring",
  },
  {
    type: "button-icon-svg",
    label: "Analytics",
    path: "/security/waf",
    icon: AnalyticsData,
  },
  {
    type: "button-icon-svg",
    label: "Logs",
    path: "/security/waf",
    icon: DocumentationList,
  },
  {
    type: "title",
    label: "Applications",
  },
  {
    type: "button-icon-svg",
    label: "Stream",
    path: "/routing/dns",
    icon: CloudflareStreamDelivery,
  },
  {
    type: "button-icon-svg",
    label: "Photos",
    path: "/security/waf",
    icon: Image,
  },
];
