import "./card.scss";

const Card = ({ title, content, className, ...props }) => {
  return (
    <div className={`${className}` + " card"}>
      <div className="meta">
        <h5>{title}</h5>
      </div>
      <div className="cardContent">{props.children}</div>
    </div>
  );
};

export default Card;
