import "./NavBar.scss";
import { NavLink } from "react-router-dom";
import { Logo } from "./icons";

const LogoComponent = Logo;

const NavBar = (props) => (
  <div className="NavBar">
    <div className="logo">
      <LogoComponent />
    </div>
    {props.navButtons.map((button) => {
      return (
        <NavButton
          key={button.path}
          type={button.type}
          path={button.path}
          label={button.label}
          icon={button.icon}
        />
      );
    })}
  </div>
);

function NavButton(props) {
  const IconComponent = props.icon;
  return (
    <NavLink to={`${props.path}`} exact activeClassName="active">
      <div
        className={`NavButton ${props.type === "search" ? " search" : ""}
          ${props.type === "title" ? " title" : ""}
          ${props.type === "button-sans-icon" ? " sansIcon" : ""}`}
      >
        {IconComponent && <IconComponent />}
        <span className="Label">{props.label}</span>
      </div>
    </NavLink>
  );
}

export default NavBar;
