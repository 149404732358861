import "./Layout.scss";
import NavBar from "./NavBar";

const Layout = (props) => {
  return (
    <div className="layout">
      <NavBar key="application-services" navButtons={props.navButtons} />
      <main className="content">{props.children}</main>
    </div>
  );
};

export default Layout;
