import { Link } from "react-router-dom";
import "./tableItems.scss";

const TableItems = ({ to, site, state, plan, ...props }) => {
  return (
    <Link to={to}>
      <li className="tableItems">
        <span>{site}</span>
        <span>{state}</span>
        <span>{plan}</span>
      </li>
    </Link>
  );
};

export default TableItems;
