import Layout from ".././components/Layout";
import Card from "../components/Card";
import SearchModal from "../components/SearchModal";
import TableItems from "../components/TableItems";
import { initialNavButtons } from "../components/navConfig";
import "./home.scss";
import { useEffect, useState } from "react";
import ModalOverlay from "../components/ModalOverlay";

function GreetingMessage() {
  const getTime = () => {
    const today = new Date();
    const curHr = today.getHours();
    console.log(curHr);
    if (curHr >= 4 && curHr <= 11) {
      return "Good morning!";
    } else if (curHr >= 12 && curHr <= 18) {
      return "Good afternoon!";
    } else {
      return "Good evening!";
    }
  };
  return <h2>{`${getTime()}`}</h2>;
}

function HomePage() {
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);

  const onCloseSearchModal = () => {
    setIsSearchModalVisible(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 75 && e.metaKey) {
      setIsSearchModalVisible((isVisible) => !isVisible);
    }
    if (e.keyCode === 27) {
      setIsSearchModalVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {}, []);

  return (
    <>
      <Layout key="home" navButtons={initialNavButtons}>
        <GreetingMessage />
        <div className="card-contents">
          <Card className="full-length" title="Applications">
            <ul className="applicationsList">
              <TableItems
                site="any-is-not-all.com"
                state="Active"
                plan="Enterprise"
              />
              <TableItems
                to="/application-services"
                site="syeef.design"
                state="Active"
                plan="Free"
              />
              <TableItems
                site="syeefkarim.com"
                state="Active"
                plan="Enterprise"
              />
              <TableItems site="test.io" state="Active" plan="Enterprise" />
              <TableItems
                site="theburritobot.com"
                state="Active"
                plan="Enterprise"
              />
            </ul>
          </Card>
          <Card className="full-length" title="Data Centers">
            <ul>
              <TableItems
                site="2.2.2.2"
                state="Active"
                plan="This is the description"
              />
              <TableItems
                site="80.64.220.0/24"
                state="Active"
                plan="MT EMEA Lab"
              />
              <TableItems
                site="80.08.80.08"
                state="Active"
                plan="This is the description"
              />
              <TableItems
                site="3.4.5.6"
                state="Active"
                plan="This is the description"
              />
            </ul>
          </Card>

          <Card
            className="full-length performance-analytics"
            title="Performance Analytics"
          >
            <Card title="Requests">Imagine a neat chart here...</Card>
            <Card title="Bandwidth">Imagine a neat chart here...</Card>
            <Card title="Visitors (Unique IPs)">
              Imagine a neat chart here...
            </Card>
          </Card>

          <Card
            className="full-length performance-analytics"
            title="Security Analytics"
          >
            <Card title="Failed logins">Imagine a neat chart here...</Card>
            <Card title="Top allowed requests">
              Imagine a neat chart here...
            </Card>
            <Card title="Top blocked requests">
              Imagine a neat chart here...
            </Card>
          </Card>

          <Card className="two-thirds" title="Create something new">
            We're going to use flexbox here to display multiple inner cards...
          </Card>
          <Card title="Cloudflare Blog">
            <ul>
              <li>
                <p>Dynamic URL Rewriting at the edge with Cloudflare</p>
                <p>
                  Today we are announcing the immediate availability of
                  Transform Rules for all Cloudflare plans. Transform Rules
                  provide Cloudflare administrators with the ability to create
                  URL rewrite rules…
                </p>
              </li>
              <li>
                <p>Jonathon Dixon: Why I joined Cloudflare</p>
                <p>
                  I’m excited to announce that on March 1st, I joined Cloudflare
                  as Vice President and GM, Asia Pacific (including Japan and
                  Greater China) to help build and expand Cloudflare’s growing
                  customer and partner base and presence in the region…
                </p>
              </li>
              <li>
                <p>The Teams Dashboard: A New Place to Call Home</p>
                <p>
                  I’m excited to announce that on March 1st, I joined Cloudflare
                  as Vice President and GM, Asia Pacific (including Japan and
                  Greater China) to help build and expand Cloudflare’s growing
                  customer and partner base and presence in the region...
                </p>
              </li>
            </ul>
          </Card>
        </div>
      </Layout>
      {isSearchModalVisible && <SearchModal onClose={onCloseSearchModal} />}
      {isSearchModalVisible && <ModalOverlay />}
    </>
  );
}

export default HomePage;
