import "./searchModal.scss";

const SearchModal = ({ onClose }) => {
  return (
    <div className="searchModal">
      <div className="inputContainer">
        <input
          type="text"
          placeholder="Type a command or search..."
          onKeyDown={null}
          autoFocus
        />
      </div>
      <div className="results">
        <ul>
          <li>Item 1</li>
          <li>Item 2</li>
          <li>Item 3</li>
        </ul>
      </div>
    </div>
  );
};

export default SearchModal;
