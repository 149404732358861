import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import {
  applicationServicesNavButtons,
  dataCenterServicesNavButtons,
  teamsNavButtons,
  creatorsNavButtons,
  initialNavButtons,
} from "./components/navConfig";
import HomePage from "./pages/home";
import StandardPage from "./pages/standard";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/application-services">
            <StandardPage navButtons={applicationServicesNavButtons}>
              This is the Applications Overview page.
              <ul>
                <li>
                  Imagine you've got a zone selected, you'll be able to adjust
                  the settings for that zone here...
                </li>
                <li>
                  We'll also give users an easy way to hot swap to another
                  zone... likely using the header...
                </li>
                <li>
                  Finally we can create a view where they can see all of their
                  zones and pick one from there...
                </li>
              </ul>
            </StandardPage>
          </Route>
          <Route path="/data-centre-services">
            <StandardPage navButtons={dataCenterServicesNavButtons}>
              This is the Network Overview page.
            </StandardPage>
          </Route>
          <Route path="/teams">
            <StandardPage navButtons={teamsNavButtons}>
              This is the Teams Overview page.
            </StandardPage>
          </Route>
          <Route path="/creators">
            <StandardPage navButtons={creatorsNavButtons}>
              This is the Creators Overview page.
            </StandardPage>
          </Route>
          <Route path="/">
            <HomePage></HomePage>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
